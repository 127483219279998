import React from 'react'
import Footer from '../components/Footer'
import ContactUs from '../components/ContactUs'
// import GoogleMap from '../components/GoogleMap'
import './ContactPage.css'
import Navbar2 from '../components/Navbar2' 

const ContactPage = () => {
  return (
    <div id='contact'>
        <div id="top"></div>
        <Navbar2  />
        <ContactUs />
        <div className='map' style={{position:"relative", bottom: 100}}>
        {/* <GoogleMap latitude={37.7749} longitude={-122.4194} zoom={12} /> */}
        </div>
        <div style={{position:'relative'}}>
        <Footer />
        </div>
    </div>
  )
}

export default ContactPage;