import React from 'react'
import Footer from '../components/Footer'
import Article3 from '../components/Article3'
import Navbar2 from '../components/Navbar2'

const ArticlePage3 = () => {
  return (
    <div>
      <Navbar2 />
      <div style={{marginTop:100}}>
       <Article3 /></div>
        <Footer />

    </div>
  )
}

export default ArticlePage3;