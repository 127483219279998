import { useState } from 'react';
import { Container, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import './ContactUs.css';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import axios from 'axios';

function ContactUs() {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to control the confirmation modal

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('Name', name);
    formData.append('Phone Number', phoneNumber);
    formData.append('Email', email);
    formData.append('Address', address);
    formData.append('Notes', notes);

    try {
      await axios.post('https://formspree.io/f/xvoeapjk', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowConfirmationModal(true); // Show confirmation modal on success
      setName('');
      setPhoneNumber('');
      setEmail('');
      setAddress('');
      setNotes('');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Container className='contact-container' style={{height: '1000px', position:'relative', top:"200px"}}>
      <center>
        <h1 className='contact-title'>Contact Us Today</h1>
        <Row>
          <Col lg={4} md={12} sm={12}>
            <div className='btbg' style={{ background: '#f5f5f5', padding: '10px', borderRadius: '40px', marginRight: '10px', marginLeft: '10px' }}>
              <h3 style={{fontWeight:'bolder'}}>Location</h3>
              <div className="iconButt">
                <FaMapMarkerAlt className='contact-icon' style={{ margin: 10, transition: "color 0.4s ease-in-out" }} size={50} />
              </div>
              <div className='contact-subtitle'>Concord, CA</div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className='btbg' style={{ background: '#f5f5f5', padding: '10px', borderRadius: '40px', marginRight: '10px', marginLeft: '10px' }}>
              <h3 style={{fontWeight:'bolder'}}>Email</h3>
              <div className="iconButt">
                <a className='contact-link' href="mailto:Service@powellmec.com">
                  <FaEnvelope className='contact-icon' style={{ margin: 10, transition: "color 0.4s ease-in-out" }} size={50} />
                </a>
              </div>
              <div className='contact-subtitle'>Service@powellmec.com</div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className='btbg' style={{ background: '#f5f5f5', padding: '10px', borderRadius: '40px', marginRight: '10px', marginLeft: '10px' }}>
              <h3 style={{fontWeight:'bolder'}}>Phone</h3>
              <div className="iconButt">
                <a className='contact-link' href="tel:925-222-5522">
                  <FaPhone className='contact-icon' style={{ margin: 10, transition: "color 0.4s ease-in-out" }} size={50} />
                </a>
              </div>
              <div className='contact-subtitle'>(925) 222-5522</div>
            </div>
          </Col>
        </Row>
        <h2 style={{fontWeight:'bolder',marginTop:50}}>We Are Eager To Hear From You</h2>
        <h4 style={{marginBottom:25}}>Please enter your email and phone number on our contact form and we will be happy to assist you.</h4>
      </center>

      <Form onSubmit={handleFormSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          {/* <Form.Label>Name*</Form.Label> */}
          <Form.Control
            name='Name'
            style={{borderRadius:"40px", borderColor:'black', height:50}}
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPhoneNumber">
          {/* <Form.Label>Phone Number*</Form.Label> */}
          <Form.Control
            name='phone #'
            style={{borderRadius:"40px",borderColor:'black', height:50}}
            type="text"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          {/* <Form.Label>Email*</Form.Label> */}
          <Form.Control
            name='email'
            style={{borderRadius:"40px",borderColor:'black', height:50}}
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formAddress">
          {/* <Form.Label>Address</Form.Label> */}
          <Form.Control
            name = 'address'
            style={{borderRadius:"40px",borderColor:'black', height:50}}
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formNotes">
          {/* <Form.Label>Notes</Form.Label> */}
          <Form.Control
          name = 'notes'
          style={{borderRadius:"15px",borderColor:'black', height:150}}
            as="textarea"
            rows={3}
            placeholder="Enter any additional notes"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
          />
        </Form.Group>

        <Button style={{borderRadius:"40px", height:50, fontWeight:'bolder'}} className='contact-btn'  type="submit">
          Submit
        </Button>
      </Form>

      
      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Thank you for reaching out. We have received your message and will get back to you soon.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ContactUs;
