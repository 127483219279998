import React from 'react'
import Footer from '../components/Footer'
import WhySealTech from '../components/WhySealTech'
import Navbar2 from '../components/Navbar2'

const OurStoryPage = () => {
  return (
    <div>
        {/* <Navbarr isHomePage={true} /> */}
        <Navbar2 />
        {/* <OurStory /> */}
        <WhySealTech/> 
        <Footer />

    </div>
  )
}

export default OurStoryPage