import React from 'react'
import Services from '../components/Services'
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection'
import Slideshow from '../components/Slideshow'
import ReviewPage from '../components/Review'
import Navbar2 from '../components/Navbar2'

const Home = ({showModal, setShowModal}) => {
  const isHome = true;
  return (
    <div>
       <Navbar2 />
      <HeroSection showModal={showModal} setShowModal={setShowModal} />
      <Services showModal={showModal} setShowModal={setShowModal} />
      <Slideshow />
      <ReviewPage/>
      <Footer isHome={isHome} ></Footer>
    </div>
  )
}

export default Home