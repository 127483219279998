import React from 'react'
import Navbar2 from '../components/Navbar2'
import Services from '../components/Services'
import Footer from '../components/Footer'
import QuoteModal from '../components/QuoteModal'

const ServicePage = ({showModal, setShowModal}) => {
  return (
    <div>
        <Navbar2/>
        <div style={{marginTop: 100}} >
        <Services showModal={showModal} setShowModal={setShowModal} /></div>
        <QuoteModal showModal={showModal} setShowModal={setShowModal} />
        <Footer/>
        
    </div>
  )
}

export default ServicePage