import React from 'react'
import Footer from '../components/Footer'
import Article4 from '../components/Article4'
import Navbar2 from '../components/Navbar2'

const ArticlePage4 = () => {
  return (
    <div>
      <Navbar2 />
      <div style={{marginTop:100}}>
       <Article4 /></div>
        <Footer />

    </div>
  )
}

export default ArticlePage4;