import React, { useState } from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import "./QuoteModal.css";
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';

function QuoteModal({ showModal, setShowModal}) {
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      await axios.post('https://formspree.io/f/xvoeapjk', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Email success");
      setSubmitted(true); // Set submitted to true to show the confirmation message
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleClose = () => {
    setShowModal(!showModal);
    setSubmitted(false); // Reset submitted state when closing the modal
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>
        <Button className='modal-close' onClick={handleClose}><FaTimes/></Button>
        {submitted ? (
          <div style={{marginTop:50}} className="confirmation-message">
            <h3>Thank you for your request. We will get back to you shortly.</h3>
          </div>
        ) : (
          <>
            <div className="header-image">
              <Image style={{width:200, marginTop:40, marginBottom: 20}} src='/images/Powell_Logo.png' fluid />
            </div>
            <center>
              <h4 className='modal-title'>REQUEST SERVICE</h4>
            </center>
            <div className="form-container">
              <form onSubmit={handleFormSubmit}>
                <div className="form-row">
                  <div className="form-group">
                    <input name="fullName" placeholder='Full Name*' type="text" className="form-control" id="fullName" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <input name="address" placeholder='Address*' type="text" className="form-control" id="address" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <input name="emailAddress" placeholder='Email Address*' type="email" className="form-control" id="emailAddress" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <input name="contactNumber" placeholder='Contact Number*' type="text" className="form-control" id="contactNumber" required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <select name="serviceRequired" className="form-control" id="serviceRequired" defaultValue="" required>
                      <option value="" disabled>Select a service</option>
                      <option value="AC Service">AC Service</option>
                      <option value="AC Repair">AC Repair</option>
                      <option value="New AC">New AC</option>
                      <option value="Furnace Maintenance">Furnace Maintenance</option>
                      <option value="Furnace Repair">Furnace Repair</option>
                      <option value="New Furnace">New Furnace</option>
                      <option value="Water Heater Maintenance and Repair">Water Heater Maintenance and Repair</option>
                      <option value="Water Heater Replacement">Water Heater Replacement</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <textarea name="additionalNotes" placeholder='Additional Notes*' style={{borderRadius:10}} className="form-control" id="additionalNotes" rows="5" required></textarea>
                  </div>
                </div>
                <div className="form-row">
                  <button type="submit" className="btn btn-primary modal-btn">Request Service</button>
                </div>
              </form>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default QuoteModal;
