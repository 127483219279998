import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { FaBars, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import './Navbar2.css'

const Nav = styled.nav`
  background: white;
  box-shadow: 0 8px 6px -4px gray; // Added shadow effect, made bigger
  height: 140px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
`;

const PhoneNumber = styled.div`
  background: #ec2424; // Nice background for the phone number
  color: white; // Shade of red used elsewhere
  font-size: 20px;
  padding: 10px 10px 10px 10px;
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 5px; // Some rounding for aesthetics
  z-index: 17; // Above the fixed navbar elements

  @media screen and (max-width: 768px) {

    padding: 5px 5px 5px 5px;

  }

`;

const NavLogo = styled.div`
  padding: 0.5rem 0;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (min-width: 769px) {
    position: fixed; // Navlinks position fixed
    right: 60px; // Move over to the left
    top: 55px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 120px;
    left: ${({ open }) => (open ? "0" : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: white; // Ensures visibility on small screens
    z-index: 15; // Elevates above other elements
    // margin-bottom: -100px;
  }
`;

const NavLinkStyled = styled(NavLink)`
  color: #2488ec;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100px;
  cursor: pointer;
  font-size: 20px;
  &.active {
    color: #ec2424;
  }

  @media screen and (min-width: 769px) {
    margin-right: auto; // Adjust links to not hang off the right
  }

  @media screen and (max-width: 768px) {
    padding: 0px; // Increased padding for touch targets
    border-bottom: 1px solid #ffffff; // Adds a visual separator
    align-items: start; // Aligns links to the start of the containing div
    justify-content: center; // Centers links horizontally

  }
`;

const Bars = styled(FaBars)`
  display: none;
  color: #808080;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 35px;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 16; // Ensures icon is above the menu
  }
`;

const Navbar2 = () => {
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setOpen(!open);
  };

  return (
    <Nav>
      <div style= {{position: 'fixed', right:135,top:10, zIndex:'17'}}>
        <a  href="tel:925-222-5522">
       <FaPhoneAlt   size ={20}  /></a>
       </div>
      <PhoneNumber className='nav-links'> (925) 222-5522 </PhoneNumber>
      <NavLogo as={Link} to="/" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}} >
        <img  src="/images/Powell_Logo.png" alt="Logo" style={{ width: '115px', height: 'auto', position: 'fixed', left: '10px', top: '0px' }} />
      </NavLogo>
      <Bars onClick={toggleNav} />
      <NavMenu open={open}>

        <NavLinkStyled className="nav-links" to="/" exact onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}}>Home</NavLinkStyled>
        <NavLinkStyled className="nav-links" to= '/services' onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}}>Services</NavLinkStyled>
        <NavLinkStyled className="nav-links" to="/our-story" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}}>About Us</NavLinkStyled>
        <NavLinkStyled className="nav-links" to="/blog" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}}>Blog</NavLinkStyled>
        <NavLinkStyled className="nav-links" to="/contact" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); setOpen(false)}}>Contact Us</NavLinkStyled>
      </NavMenu>
    </Nav>
  );
};

export default Navbar2;