import React from 'react'
import Footer from '../components/Footer'
import BlogList from '../components/Blog'
import Navbar2 from '../components/Navbar2'

const OurStoryPage = () => {
  return (
    <>
    <div style={{margin:100}}>
        <Navbar2 />
        </div>
        <BlogList />
        <Footer />

    </>
  )
}

export default OurStoryPage