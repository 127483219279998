import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./WhySealTech.css"

const WhySealTech = () => {
  return (
    <Container className="why-sealtech-container" style={{  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.477)",
      background: "#f5f5f5"}}>

    <center>
      <h1 className='why-title'>About <span style={{color:"#ec2424"}}>Us</span></h1>
      <h2 className='why-subtitle'>Our Story</h2>
      <Row>
        <Col xs={12} md={6} style={{background:'white', borderRadius:"40px", border: "10px solid white", boxShadow:"0 2px 8px rgba(0, 0, 0, 0.477)"}}>
        <Image src='/images/Powell_Logo.png' fluid/>
        </Col>
        <Col xs={12} md={6}>
                <p className='why-description'>Jeremey Powell, the owner of Powell Mechanical, brings over two decades of proficiency, passion and honesty to the HVAC industry. Founded on the principles of integrity, quality, and unwavering dedication to customer satisfaction, Powell Mechanical has established itself as a leading HVAC service provider in the community. Our mission is to ensure your heating, ventilation, and air conditioning systems are operating at peak efficiency, providing a comfortable and safe environment for you and your family. At Powell Mechanical, your comfort is our priority, and we strive to deliver solutions that not only meet but exceed your expectations.</p>
         </Col>
      </Row>
      <h2 className='why-subtitle'>Why Clients Choose Us?</h2></center>
      <p className='why-description'>
      At Powell mechanical we stand out as your preferred choice for heating, ventilation, and air conditioning services. As a family-owned and operated business, honesty is the cornerstone of our values. Our commitment to transparency and integrity ensures that you receive not just service, but a partnership you can trust. We take pride in delivering top-notch HVAC solutions, combining our expertise with a personalized touch. Choose us for a warm, family-oriented approach to keeping your home comfortable – where honesty meets excellence in every service we provide.       </p>
      <center>
      <h4 className='why-equip'>Save On Top Brands!</h4></center>
      <Row className="equipment-row">
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white', border: "5px solid transparent"}} src="/images/bosch.png" alt="Image 1" fluid />
          {/* <h5 className='equipment-name'>Swirly Pro Surface Pressure Washer</h5> */}
        </Col>
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white', border: "5px solid transparent"}} src="/images/Payne.png" alt="Image 2" fluid />
        </Col>
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white', border: "5px solid transparent"}} src="/images/adp.png" alt="Image 3" fluid />
        </Col>
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white', border: "5px solid transparent"}} src="/images/coleman.png" alt="Image 4" fluid />
        </Col>
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white',  border: "5px solid transparent"}} src="/images/goodman.jpg" alt="Image 5" fluid />
        </Col>
        <Col md={4}>
          <Image className='why-img' style={{borderRadius:"40px", background:'white', border: "5px solid transparent"}} src="/images/carrier.png" alt="Image 6" fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default WhySealTech;
