import React, { useState, useEffect } from 'react';
import './Slideshow.css';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ['/Images/hero1.png','/Images/hero2.png','/Images/hero3.png'];


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  return (
    <div id="home" className="slide-section">
      <div className="slide-container">
        {images.map((image, index) => (
          <div key={index} className={`slidee ${index === currentImageIndex ? 'active' : ''}`}>
            <img src={image} alt={`Slidee ${index + 1}`} />
          </div>
        ))}
        <div className="slide-content">
          <h1 className="slide-title1">PRECISION CLIMATE CONTROL, EXCEPTIONAL SERVICE</h1>
          <h2 className="slide-title">WE KEEP YOU COMFORTABLE ALL YEAR ROUND</h2>
          <Link to = "/contact#top" >
          <button style={{marginTop:'40px'}} onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });}} className="free-quote">Contact Us Today <FaArrowRight/></button></Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
