import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Blog.css';
import { FaAngleDoubleRight, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BlogPage = () => {
    const blogs = [
        {
          id: 1,
          title: 'The Wise Investment: Unveiling the Importance of Preventative HVAC Maintenance',
          image: '/images/blog1.png',
          description: 'In the world of heating, ventilation, and air conditioning (HVAC), the adage "an ounce of prevention is worth a pound of cure" couldn\'t be more accurate.',
          date: 'June 1, 2023',
          path: '/article1'
        },
        {
          id: 2,
          title: 'The Honest Advantage: Why Choosing a Family-Owned HVAC Contractor is More Affordable',
          image: '/images/blog2.png',
          description: 'In the world of HVAC services, navigating cost differences between companies can be like deciphering a complex puzzle.',
          date: 'June 2, 2023',
          path: '/article2'
        },
        {
          id: 3,
          title: 'Harnessing the Power of Nature: The Honest Advantages of Combining Solar Energy with Heat Pumps',
          image: '/images/blog3.png',
          description: 'In an era where sustainable solutions are at the forefront, the synergy between solar energy and heat pumps emerges as a powerful, environmentally conscious choice. ',
          date: 'June 3, 2023',
          path: '/article3'

        },
        {
          id: 4,
          title: 'Unveiling the Truth about Tankless Water Heaters: An Honest Exploration',
          image: '/images/blog3.png',
          description: 'As a family-owned HVAC contractor, our commitment to honesty extends to every aspect of home comfort solutions, and one innovation that deserves attention is the tankless water heater.',
          date: 'June 4, 2023',
          path: '/article4'

        },
        // {
        //   id: 5,
        //   title: '7 Top Paint Stripping Precautions To Keep Your Team Safe',
        //   image: '/images/blog2.png',
        //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   date: 'June 5, 2023',
        // },
        // {
        //   id: 6,
        //   title: '7 Top Paint Stripping Precautions To Keep Your Team Safe',
        //   image: '/images/blog1.png',
        //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   date: 'June 6, 2023',
        // },
      ];
      

  return (
    <Container className='blog-container' style={{position:"relative"}}>
      <h1 className="blog-title">Blogs</h1>
      <Row className="blog-row">
        {blogs.map((blog) => (
          <Col key={blog.id} lg={4} md={6} xs={12}>
            <Card className="blog-card">
              <Card.Img style={{background:"black"}} variant="top" src={'/images/Powell_Logo.png'} className="blog-image" />
              <Card.Body className='card-body'>
                <Card.Title style={{fontWeight:"900", marginBottom:20}}>{blog.title}</Card.Title>
                <Card.Text style={{marginBottom:30}}>{blog.description}</Card.Text>
                <div>
                <Button onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });}} as={Link} to={blog.path} style={{background:'transparent', color:'#2488ec', borderWidth:0, fontWeight:800 }} variant="primary">READ MORE<FaAngleDoubleRight style={{marginLeft:5}} size={10}/></Button>
                <hr />
                <Card.Text className="blog-date">{blog.date}</Card.Text></div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogPage;
