import {useState} from 'react';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import OurStoryPage from './pages/OurStoryPage';
import BlogPage from './pages/BlogPage';
import ArticlePage from './pages/ArticlePage';
import ServicePage from './pages/ServicePage';
import ArticlePage2 from './pages/ArticlePage2';
import ArticlePage3 from './pages/ArticlePage3';
import ArticlePage4 from './pages/ArticlePage4';

function App() {

  const [showModal, setShowModal] = useState(false);


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home showModal ={showModal} setShowModal = {setShowModal} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path = "/our-story" element={<OurStoryPage/>} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/article1" element={ <ArticlePage />} />
          <Route path="/article2" element={ <ArticlePage2 />} />
          <Route path="/article3" element={ <ArticlePage3 />} />
          <Route path="/article4" element={ <ArticlePage4 />} />
          <Route path = "/services" element ={<ServicePage showModal ={showModal} setShowModal = {setShowModal}  />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
