import React from 'react'
import Footer from '../components/Footer'
import Article1 from '../components/Article1'
import Navbar2 from '../components/Navbar2'

const OurStoryPage = () => {
  return (
    <div>
      <Navbar2 />
      <div style={{marginTop:100}}>
       <Article1 /></div>
        <Footer />

    </div>
  )
}

export default OurStoryPage