// src/components/Services.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import './Services.css'


// Define styled components outside of the component function
const Grid = styled.div`
position: relative;
// top: -100px;
background: #fff;
display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 70px;
  padding: 60px;
  @media (max-width: 1105px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 10px;
  }
  

`;

const Card = styled(motion.div)`
    background: #eee;
    border-radius: 10px;
  padding: 20px;
  text-align: left;
  transition: transform 0.2s;
  height: 400px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 6px -4px gray; // Added shadow effect, made bigger
    outline: 2px solid #2488ec;


  }
  @media (max-width: 301px) {
    height: 475px;
  }
 
`;

const Title = styled.h2`
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
font-family: 'Koulen', sans-serif;
  margin-top: 0;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
`;

const Description = styled.p`
  font-size: 20px;
`;

const Button = styled.span`
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
font-family: 'Koulen', sans-serif;
  color: #2488ec;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  transition: color 0.2s;
  &:hover {
    color:#ec2424;
  }
`;

// Define the ServiceCard component
const ServiceCard = ({ title, icon, description, showModal, setShowModal }) => {
  return (
    <Card
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Icon src={icon} alt={title} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button onClick={() =>{console.log("test"); setShowModal(!showModal)}}>Request Service</Button>
    </Card>
  );
};

// Define the ServicesGrid component
const ServicesGrid = ({showModal, setShowModal}) => {
  // Define your services data
  const services = [
    { title: 'AC Service', icon: '/images/AC_maintenance.png', description: 'Set up a preventive maintenance on your AC to ensure optimal energy savings and longevity of your unit!' },
    { title: 'AC Repair', icon: '/images/AC_maintenance.png', description: 'Fast, affordable and most importantly honest repairs available to get your unit cooling again.' },
    { title: 'New AC', icon: '/images/AC_maintenance.png', description: 'With energy savings and longevity always in mind we Install a wide selection of top rated brands to ensure your home’s temperature is always perfect. Call today for a quote on a new AC!' },
    { title: 'Furnace Maintenance', icon: '/images/AC_maintenance.png', description: 'Set up a preventive maintenance on your furnace to ensure safety and longevity of your unit!' },
    { title: 'Furnace Repair', icon: '/images/AC_maintenance.png', description: 'Fast, affordable and most importantly honest repairs available to get your unit heating again.' },
    { title: 'New Furnace', icon: '/images/AC_maintenance.png', description: 'With energy savings and longevity always in mind we Install a wide selection of top rated brands to ensure your home’s temperature is always perfect. Call today for a quote on a new furnace!' },
    { title: 'Water Heater Maintenance and Repair', icon: '/images/AC_maintenance.png', description: 'Cold shower? We can come out to repair and maintain your water heater.' },
    { title: 'Water Heater Replacement', icon: '/images/AC_maintenance.png', description: 'We offer the top brands and sizes of water heaters. Tankless to electric we got you covered to that hot water back. ' },
    // { title: 'New Equipment', icon: '/images/AC_maintenance.png', description: 'Need a new AC? We got you covered!' },
    // Add other services similarly...
  ];

  return (
    <div style={{position: 'relative', marginBottom:'400px', top: 100}} >
    <center><h1 id='services' className='service-title' style={{color:'#ec2424'}} >Our <span style ={{color:'#2488ec'}}>Services</span></h1></center>
    <Grid>
      {services.map((service, index) => (
        <ServiceCard
          key={index}
          title={service.title}
          icon={service.icon}
          description={service.description}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      ))}
    </Grid>
    </div>
  );
};

export default ServicesGrid;